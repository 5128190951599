import { enableProdMode, HostListener } from '@angular/core';
import { environment } from './environments/environment';

import('./bootstrap').catch(err => console.error(err));

// console log print function start
const consoleLog = true //for allow console log print use true
if(!consoleLog) {
  console.log = function() {} 
}
// console log print function end
 